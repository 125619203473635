import * as Sentry from '@sentry/nextjs'
import { useAuthWallets } from 'hooks/auth/useAuthWallets'

import { FC, ReactElement, useEffect } from 'react'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN

type Props = {
  children: ReactElement
}

const ErrorTrackingProvider: FC<Props> = ({ children }) => {
  const { ownershipWallet } = useAuthWallets()

  useEffect(() => {
    if (!SENTRY_DSN) {
      return
    }

    if (ownershipWallet?.address) {
      Sentry.setUser({ id: ownershipWallet?.address })
    } else {
      Sentry.setUser(null)
    }
  }, [ownershipWallet?.address])

  return children
}

export default ErrorTrackingProvider
