import { styled } from 'stitches.config'

export default styled('span', {
  color: '$gray12',
  fontFamily: '$body',
  letterSpacing: 0,

  variants: {
    style: {
      accordionItem: {
        fontSize: 16,
        fontWeight: 600,
      },
      cardTitle: {
        fontWeight: 700,
        fontSize: 20,
      },
      cardSubtitle: {
        fontSize: 16,
        fontWeight: 400,
      },
      h2: {
        fontWeight: 800,
        fontSize: 48,
      },
      h3: {
        fontWeight: 800,
        fontSize: 32,
      },
      h4: {
        fontWeight: 800,
        fontSize: 24,
      },
      h5: {
        fontWeight: 700,
        fontSize: 20,
      },
      h6: {
        fontWeight: 700,
        fontSize: 16,
      },
      h7: {
        fontWeight: 700,
        fontSize: 18,
      },
      subtitle1: {
        fontWeight: 700,
        fontSize: 16,
      },
      subtitle2: {
        fontWeight: 500,
        fontSize: 14,
      },
      subtitle3: {
        fontWeight: 500,
        fontSize: 12,
      },
      subtitle4: {
        fontWeight: 500,
        fontSize: 16,
      },
      subtitle5: {
        fontWeight: 600,
        fontSize: 16,
      },
      body1: {
        fontWeight: 400,
        fontSize: 16,
      },
      body2: {
        fontWeight: 400,
        fontSize: 14,
      },
      body3: {
        fontWeight: 400,
        fontSize: 12,
      },
      monospace: {
        fontFamily: 'monospace',
        color: '$deepBlue400',
        borderRadius: '4px',
        backgroundColor: '$gray3',
        padding: '2px 4px',
      },
    },
    color: {
      subtle: {
        color: '$gray11',
      },
      error: {
        color: '$red11',
      },
    },
    italic: {
      true: {
        fontStyle: 'italic',
      },
    },
    ellipsify: {
      true: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
    weight: {
      400: {
        fontWeight: 400,
      },
      500: {
        fontWeight: 500,
      },
      600: {
        fontWeight: 600,
      },
      700: {
        fontWeight: 700,
      },
      800: {
        fontWeight: 800,
      },
    },
  },

  defaultVariants: {
    style: 'body1',
  },
})
