import { useReservoirClient } from '@reservoir0x/reservoir-kit-ui'
import { StyledComponent } from '@stitches/react/types/styled-component'
import { FC } from 'react'
import { zeroAddress } from 'viem'
import { styled } from '../../stitches.config'

type Props = {
  address: string
  chainId?: number
  variant?: string
} & Parameters<StyledComponent>['0']

const StyledImg = styled('img', {})

const CryptoCurrencyIcon: FC<Props> = ({
  address = zeroAddress,
  chainId,
  css,
  variant = 'default',
}) => {
  const client = useReservoirClient()
  const chain = client?.chains?.find((chain) =>
    chainId !== undefined ? chain.id === chainId : chain.active
  )

  const imgSrc =
    variant === 'light'
      ? '/icons/eth-icon-light.svg'
      : `${chain?.baseApiUrl}/redirect/currency/${address}/icon/v1`

  return (
    <StyledImg
      src={imgSrc}
      css={css}
      // TODO - define backup images as part of the Chain Config and pass them through instead of hardcoding Weth everytime
      onError={(e) =>
        ((e.target as any).src = '/icons/currency/no-padding-weth.png')
      }
    />
  )
}

export default CryptoCurrencyIcon
