import { useState, createContext, FC, useEffect, useCallback } from 'react'

import { PriceDisplay } from 'utils/priceDisplay'

export const PriceDisplayContext = createContext<{
  priceDisplay: PriceDisplay
  setPriceDisplay: (priceDisplay: PriceDisplay) => void
}>({
  priceDisplay: PriceDisplay.ETH,
  setPriceDisplay: () => {},
})

const PriceDisplayContextProvider: FC<any> = ({ children }) => {
  const [globalPriceDisplay, setGlobalPriceDisplay] = useState(PriceDisplay.ETH)

  useEffect(() => {
    const savedPriceDisplay: string | null = localStorage.getItem(
      'reservoir.priceDisplay'
    )

    if (
      savedPriceDisplay &&
      Object.values(PriceDisplay).includes(savedPriceDisplay as PriceDisplay)
    ) {
      setGlobalPriceDisplay(savedPriceDisplay as PriceDisplay)
    }
  }, [])

  const setPriceDisplay = useCallback(
    (updatedPriceDisplay: PriceDisplay) => {
      if (updatedPriceDisplay === globalPriceDisplay) {
        return
      }

      setGlobalPriceDisplay(updatedPriceDisplay)

      if (typeof window !== 'undefined') {
        localStorage.setItem('reservoir.priceDisplay', `${updatedPriceDisplay}`)
      }
    },
    [globalPriceDisplay, setGlobalPriceDisplay]
  )

  return (
    <PriceDisplayContext.Provider
      value={{
        priceDisplay: globalPriceDisplay,
        setPriceDisplay,
      }}
    >
      {children}
    </PriceDisplayContext.Provider>
  )
}

export default PriceDisplayContextProvider
