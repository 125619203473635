import { useRouter } from 'next/router'
import { useState, useEffect, createContext, FC } from 'react'

import { nfts, chains } from 'config/routes'

export type Collection = {
  name: string
  systemId: string
  contractAddress: string
  chainName: string
}

type CollectionContextType = {
  selectedCollection: Collection | null
  setSelectedCollection: (collection: Collection | null) => void
}

export const CollectionContext = createContext<CollectionContextType>({
  selectedCollection: null,
  setSelectedCollection: () => {},
})

const CollectionContextProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter()
  const [selectedCollection, setSelectedCollection] =
    useState<Collection | null>(null)

  useEffect(() => {
    const { chain, systemId } = router.query

    if (typeof chain === 'string' && typeof systemId === 'string') {
      const nftInfo = nfts[systemId]
      const chainInfo = chains[chain]

      if (nftInfo && nftInfo.chains[chain] && chainInfo) {
        setSelectedCollection({
          name: nftInfo.name,
          systemId,
          contractAddress: nftInfo.chains[chain],
          chainName: chainInfo.name,
        })
      } else {
        setSelectedCollection(null)
      }
    } else {
      setSelectedCollection(null)
    }
  }, [router.query])

  const contextValue = {
    selectedCollection,
    setSelectedCollection,
  }

  return (
    <CollectionContext.Provider value={contextValue}>
      {children}
    </CollectionContext.Provider>
  )
}

export default CollectionContextProvider
