export type PriceDisplays = {
    darkIconUrl: string;
    lightIconUrl: string;
    tooltipText: string
    value: PriceDisplay
}

export enum PriceDisplay {
    ETH = 'ETH',
    USD = 'USD',
}

export const defaultPriceDisplays = [{
    darkIconUrl: '/icons/eth-icon-light.svg',
    lightIconUrl: '/icons/eth-icon-dark.svg',
    tooltipText: `Display ${PriceDisplay.ETH} prices`,
    value: PriceDisplay.ETH,
}, {
    darkIconUrl: '/icons/usd-dollar-icon-light.svg',
    lightIconUrl: '/icons/usd-dollar-icon-dark.svg',
    tooltipText: `Display ${PriceDisplay.USD} prices`,
    value: PriceDisplay.USD,
}] as PriceDisplays[]
