import { reservoirChains } from '@reservoir0x/reservoir-sdk'
import { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'

type Chain = Omit<(typeof reservoirChains)['mainnet'], 'websocketUrl'>

const TESTNET_CHAINS: Chain[] = [
  reservoirChains.sepolia,
  reservoirChains.apexPopTestnet,
  reservoirChains.cloud,
]

const MAINNET_CHAINS: Chain[] = [
  reservoirChains.mainnet,
  reservoirChains.apexPop,
  //todo: add Boss
]

const IS_TESTNET_DEPLOYMENT = process.env.NEXT_PUBLIC_PUBLIC_ENVIRONMENT !== 'production'

export default () => {
  const [unsupportedChain, setUnsupportedChain] = useState<Chain | undefined>(
    undefined
  )
  const { chain } = useAccount()

  useEffect(() => {
    setUnsupportedChain(
      (IS_TESTNET_DEPLOYMENT ? MAINNET_CHAINS : TESTNET_CHAINS).find(
        ({ id }) => chain?.id === id
      )
    )
  }, [chain])
  return {
    unsupportedChain,
    isTestnetDeployment: !IS_TESTNET_DEPLOYMENT,
  }
}
