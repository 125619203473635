import { ComponentType, MouseEvent, MouseEventHandler } from 'react'

import { analytics } from 'utils/helpers/analytics'

export interface ClickAnalyticsProps {
  analyticsId?: string
  analyticsProps?: Record<string, string | number | boolean>
  onClick?: MouseEventHandler
  isDisabled?: boolean
}

export const withClickAnalytics =
  <T extends object>(WrappedComponent: ComponentType<T>) =>
  ({
    analyticsId,
    analyticsProps = {},
    onClick,
    ...props
  }: T & ClickAnalyticsProps): JSX.Element => {
    const handleClick = (e: MouseEvent): void => {
      if (props.isDisabled) {
        return
      }

      if (analyticsId) {
        analytics
          .trackWebEvent(analyticsId, {
            ...analyticsProps,
          })
          .catch(console.error)
      }
      onClick?.(e)
    }
    return <WrappedComponent onClick={handleClick} {...(props as T)} />
  }
