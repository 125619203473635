import { defineChain } from 'viem'

const nativeCurrency = {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
}

// TODO: Use config to hook this up
// chain support for testnet-barret
export const barret = defineChain({
    id: 70800,
    name: 'Barret - Proof of Play Testnet',
    nativeCurrency,
    rpcUrls: {
        default: {
            http: ['https://rpc-pop-testnet-barret-oxaolmcfss.t.conduit.xyz', "https://rpc-pop-testnet-barret-oxaolmcfss.t.conduit.xyz"],
        },
    },
    blockExplorers: {
        default: { name: 'Explorer', url: "https://explorer-pop-testnet-barret-oxaolmcfss.t.conduit.xyz" },
    },
})
// chain support for testnet-cloud
export const cloud = defineChain({
    id: 70805,
    name: 'Cloud - Proof of Play Testnet',
    nativeCurrency,
    rpcUrls: {
        default: {
            http: ['https://rpc-pop-testnet-cloud-fmg1z6e0a9.t.conduit.xyz', "https://rpc-pop-testnet-cloud-fmg1z6e0a9.t.conduit.xyz"],
        },
    },
    blockExplorers: {
        default: { name: 'Explorer', url: "https://explorer-pop-testnet-cloud-fmg1z6e0a9.t.conduit.xyz" },
    },
});

// chain support for apex
export const apex = defineChain({
    id: 70700,
    name: 'Apex - Proof of Play Mainnet',
    nativeCurrency,
    rpcUrls: {
        default: {
            http: ['https://rpc.apex.proofofplay.com', "https://rpc2.apex.proofofplay.com/3999e7f4ed3565d722bc0d5eb3145b834fde2e88/"],
        },
    },
    blockExplorers: {
        default: { name: 'Explorer', url: "https://explorer.apex.proofofplay.com" },
    },
});

// chain support for boss
export const boss = defineChain({
    id: 70701,
    name: 'Boss - Proof of Play Mainnet',
    nativeCurrency,
    rpcUrls: {
        default: {
            http: ['https://rpc.boss.proofofplay.com', "https://proof-of-play-boss.nirvanalabs.xyz/bossmainsg-yr56g?apikey=1d4701dfaacb28adc56f9e35dc61ad736ebc"],
        },
    },
    blockExplorers: {
        default: { name: 'Explorer', url: "https://explorer.boss.proofofplay.com" },
    },
});

export const popChainsConfig = [apex, boss, barret, cloud];
