import * as amplitude from "@amplitude/analytics-browser";

// DEVS: Set to true to enable debug mode w/ more logging for analytics
const DEBUG_MODE = process.env.NODE_ENV === "development";

export const trackWebEvent = (
    name: string,
    additionalProperties: { [key: string]: string | number | boolean } = {}
): Promise<void | amplitude.Types.Result> => {
    let host;
    let url;
    if (typeof window !== "undefined") {
        host = window.location.host;
        url = window.location.href;
    }

    const properties = {
        "source platform": "Web",
        host,
        url,
        ...additionalProperties,
    };

    if (DEBUG_MODE) {
        console.log("[Analytics]", "[Marketplace]", name, properties);
    }

    return amplitude.track(name, properties).promise;
};

export const identify = (
    id: string,
    properties?: { [key: string]: string | number | boolean }
): void => {
    amplitude.setUserId(id);
    if (properties) {
        const identifyObj = new amplitude.Identify();
        Object.entries(properties).map(([key, value]) =>
            identifyObj.set(key, value)
        );
        amplitude.identify(identifyObj);
    }
};

export const resetAnalyticsIdentifiers = (): void => {
    amplitude.reset();
};

export const analytics = {
    trackWebEvent,
    identify,
    resetAnalyticsIdentifiers,
};
