import { PrivyProvider as PrivySdkProvider } from '@privy-io/react-auth'
import { PropsWithChildren } from 'react'
import { popChainsConfig } from 'lib/viem/chains'
import { mainnet, sepolia } from 'viem/chains'

const PRIVY_APP_ID = process.env.NEXT_PUBLIC_PRIVY_APP_ID ?? ''
const PRIVY_CLIENT_ID = process.env.NEXT_PUBLIC_PRIVY_CLIENT_ID ?? ''

export const PrivyProvider = ({ children }: PropsWithChildren<{}>) => (
  <PrivySdkProvider
    appId={PRIVY_APP_ID}
    clientId={PRIVY_CLIENT_ID}
    config={{
      // Customize Privy's appearance in your app
      appearance: {
        loginMessage: 'Login to your Proof of Play account',
        landingHeader: 'Login or signup',
        theme: 'dark',
        accentColor: '#696FFD',
        walletList: [
          'metamask',
          'rabby_wallet',
          'coinbase_wallet',
          'wallet_connect',
          'detected_wallets',
        ],
      },
      // Don't create wallets by default
      embeddedWallets: {
        createOnLogin: 'off',
      },
      supportedChains: [mainnet, sepolia, ...popChainsConfig],
    }}
  >
    {children}
  </PrivySdkProvider>
)
